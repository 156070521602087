<template>
  <div id="app">
    <img v-if="backgroundImage" :src="backgroundImage" class="bg" />
    <div class="header-style" :style="headerStyleObject">
      <div :style="logoContainerStyle">
        <a :href="websiteUrl" target="_new"
          ><img :src="`img/${$omwConfig.display.companyLogo}`" :style="headerImageStyleObject" />
        </a>
      </div>
    </div>

    <div v-if="separatorEnabled" class="header-separator" />
    <div class="app-background pb-2 main-content">
      <div v-if="separatorEnabled" style="height: 2em" class="app-background" />
      <span v-if="error">
        <Error></Error>
      </span>
      <span v-else>
        <keep-alive :exclude="['Home', 'MainContainer', 'AppointmentRebookContainer', 'PhotoAttach']">
          <router-view />
        </keep-alive>
      </span>
    </div>
    <the-footer class="footer" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';
import Error from '@/components/Error.vue';

export default defineComponent({
  components: {
    TheFooter: () => import(/* webpackPrefetch: true */ '@/components/layout/TheFooter.vue'),
    Error,
  },
  data() {
    return {
      headerStyleObject: {
        height: this.$omwConfig.display.headerHeight,
      },
      websiteUrl: this.$omwConfig.display.customerWebsite,
    };
  },
  computed: {
    ...mapGetters(['token', 'error']),
    backgroundImage() {
      if (this.$omwConfig.display.backgroundImage) {
        return `img/${this.$omwConfig.display.backgroundImage}`;
      }
      return undefined;
    },
    logoContainerStyle() {
      const style = {};
      if (this.$omwConfig?.display.companyLogoPosition) {
        style.display = 'flex';
        style.justifyContent = this.$omwConfig.display.companyLogoPosition.position;

        style.padding = this.$omwConfig.display.companyLogoPosition.padding;
      }
      return style;
    },
    headerImageStyleObject() {
      const style = {
        height: this.$omwConfig.display.imageHeight,
        width: this.$omwConfig.display.imageWidth,
      };
      if (this.$omwConfig?.display?.companyLogoPosition?.margin) {
        style.margin = this.$omwConfig?.display?.companyLogoPosition?.margin;
      }
      if (this.$omwConfig?.display?.companyLogoPosition?.padding) {
        style.padding = this.$omwConfig?.display?.companyLogoPosition?.padding;
      }
      return style;
    },
    separatorPadding() {
      if (this.separatorEnabled) {
        return { 'margin-top': '2em' };
      } else return '';
    },
  },
  beforeCreate() {
    if (this.$omwConfig?.trustpilot?.enabled) {
      const trustpilotScript = document.createElement('script');
      trustpilotScript.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
      trustpilotScript.setAttribute('async', 'async');
      document.head.appendChild(trustpilotScript);
    }
  },
  async created() {
    this.setError(undefined);
    this.setToken(this.$route?.query?.token);
    this.setClickOrigin(this?.$route?.query?.clickOrigin);
  },
  methods: {
    ...mapActions(['setToken', 'setError', 'setClickOrigin']),
  },
});
</script>

<style lang="scss">
@import '@/sass/buefy-init.scss';
@import '@/sass/variables.scss';

#app {
  font-family: Custom, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@if variable-exists(custom-font-family) {
  @font-face {
    font-family: 'Custom';
    src: url($custom-font-family);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@if variable-exists(button-border-radius) {
  button {
    border-radius: $button-border-radius !important;
  }
}

.header-style {
  background-color: $header-background;
}
.app-background {
  background-color: $main-background;
  color: $font-color;
}

img.bg {
  min-height: 100vh;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.header-separator {
  background-color: $separator-color;
  width: 100vw;
  height: 30px;
}
.main-content {
  min-height: calc(100vh - 128px);
}
.footer {
  height: 50px;
}
</style>
